import { environment as original } from './environment.prod';

export const environment = {
  ...original,
  name: 'prod-without-excuseme',
  featureToggle: {
    ...original.featureToggle,
    withCallWaiter: false,
    withCallWaiterToRepeat: false,
    withCallWaiterToPay: false,
    withServiceCentre: false,
  }
};
